/**
 * Interface between application's calendar events and the API.
 */
 export default class CalendarEvent {
  constructor (color, description, name, start, eventType, eventGroupType) {
    this.color = color.color
    this.dark = color.dark
    this.description = description
    this.start = start
    this.name = name
    this.eventType = eventType
    this.eventGroupType = eventGroupType

    this.created = false
    this.stale = false
  }

}