<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="10" class="pa-0">
      <v-card
        class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 pb-4"
        max-width="100%"
        outlined
        :loading="refreshingEvents"
      >
        <CardToolbar
          :disableSave="!hasStaleEvents"
          :icon="CALENDAR_ICON"
          @add-data="openEventDialog()"
          @add-event-group="openEventGroupDialog()"
          @refresh-data="refreshEvents()"
          @save-data="saveEvents()"
          class="mt-4"
          button-mode
          include-add
          include-add-event-group
          include-refresh
          include-save
          title="Mailing Schedule"
        />
        <v-card-text class="mt-n4 pb-0">
          <p :class="`${DEFAULT_TEXT}`">A calendar containing events related to InvisMI iCare.</p>
        </v-card-text>
        
        <v-alert
          v-show="hasStaleEvents"
          outlined
          prominent
          text
          type="warning"
        >
          The calendar contains unsaved changes.<br>Remember to <span class="green--text">save</span> (<v-icon small color="success">{{ SAVE_ICON }}</v-icon>) the calendar before leaving this page.
        </v-alert>

        <v-card
          class="d-flex align-center justify-center mb-4"
          flat
        >
          <!-- Adjust years. -->
          <v-btn @click="$refs.calendar.move(-12)" color="primary lighten-3" icon>
            <v-icon v-text="BACK_ICON" />
          </v-btn>
          <v-sheet class="text-center text-h5 primary--text v-default-cursor" v-text="currentYear" width="150" />
          <v-btn @click="$refs.calendar.move(12)" class="mr-8" color="primary lighten-3" icon>
            <v-icon v-text="FORWARD_ICON" />
          </v-btn>
  
          <!-- Adjust months. -->
          <v-btn @click="$refs.calendar.prev()" class="ml-8" color="primary lighten-3" icon>
            <v-icon v-text="BACK_ICON" />
          </v-btn>
          <v-sheet class="text-center text-h5 primary--text v-default-cursor" v-text="currentMonth" width="150" />
          <v-btn color="primary lighten-3" @click="$refs.calendar.next()" icon>
            <v-icon v-text="FORWARD_ICON" />
          </v-btn>
        </v-card>

        <v-sheet :height="tableHeight" min-height="610">
          <v-calendar
            v-model="value"
            :event-overlap-mode="mode"
            :event-overlap-threshold="60"
            :events="events"
            :key="calendarInstance"
            :type="type"
            :weekdays="weekday"
            @change="getEvents"
            @click:event="openEventDialog"
            @click:more="handleMoreEvents"
            color="primary"
            ref="calendar"
          >
            <template v-slot:event="{ event }">
              <div
                @mouseenter="mousingOverEvent=event.index; showEvent($event, event)"
                @mouseleave="mousingOverEvent=false; handleMouseLeaveEvent(event.index)"
                :class="`pl-3 ${event.dark ? '' : 'black--text'}`"
              >
                <v-icon
                  v-if="event.stale"
                  :dark="event.dark"
                  left
                  x-small
                  v-html="STALE_EVENT_ICON"
                />
                {{ event.name }}
              </div>
            </template>
          </v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :position-x="selectedEventElementMenuPosition.x"
            :position-y="selectedEventElementMenuPosition.y"
            :transition="false"
            offset-x
          >
            <v-card
              @mouseenter="mousingOverMenu = true"
              @mouseleave="mousingOverMenu = false; handleMouseLeaveEvent(selectedEvent.index)"
              color="grey lighten-4"
              flat
              :min-width="eventMenuWidth"
              :max-width="350"
            >
              <v-toolbar
                :color="selectedEvent.color"
                :dark="selectedEvent.dark"
              >
                <v-toolbar-title v-html="selectedEvent.name" />
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.description" />
              </v-card-text>
            </v-card>
          </v-menu>
          <v-dialog
            v-model="more"
            hide-overlay
            scrollable
            width="300"
          >
            <v-card tile>
              <v-toolbar
                flat
                dark
                color="primary"
              >
                Events on {{ moreDate }}
              </v-toolbar>
              <v-list>
                <v-list-item
                  v-for="(event, index) in moreEvents" :key="index"
                  @click="openEventDialogFromList(event)"
                  :class="`pl-3 ${event.dark ? '' : 'black--text'} ${event.color}`"
                  :three-line="event.description && event.description.length > 25"
                  :two-line="event.description && event.description.length >= 10 && event.description.length <= 25"
                  ripple
                  style="cursor: pointer;"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon
                        v-if="event.stale"
                        :dark="event.dark"
                        left
                        x-small
                        v-html="STALE_EVENT_ICON"
                      />
                      {{ event.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ event.description }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-dialog>
        </v-sheet>

        <DialogEventGroups
          :config="dialog[EVENT_GROUP].config"
          :dialog.sync="dialog[EVENT_GROUP].show"
          @add-event-group="addEventGroup"
        />
        <DialogEvent
          :config="dialog[EVENT].config"
          :dialog.sync="dialog[EVENT].show"
          @add-event="addEvent"
          @delete-event="deleteEvent"
          @update-event="updateEvent"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { COLOR, DIALOGS, ICONS, TEXT_COLOR } from '../constants'
import { monthNumberToName, now } from '../services/utility.service'
import CalendarEventFactory from '../models/CalendarEventFactory'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MailingSchedule',
  components: {
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue'),
    TooltipFabIconButton: () => import('../components/input/TooltipFabIconButton.vue'),
    DialogEvent: () => import('../components/layout/dialog/DialogEvent.vue'),
    DialogEventGroups: () => import('../components/layout/dialog/DialogEventGroup.vue'),
  },
  data: () => ({
    type: 'month', // Display type of calendar.
    mode: 'stack', // Display mode of calendar.
    weekday: [0, 1, 2, 3, 4, 5, 6], // Days to include in calendar and their order.
    
    events: [], // Events displayed in the calendar.
    
    calendarInstance: 0,

    // Dialog window state.
    dialog: {
      [DIALOGS.EVENT]: { show: false, payload: [], config: {} },
      [DIALOGS.EVENT_GROUP]: { show: false, payload: [], },
    },

    value: '',

    currentMonth: '', // Current month displayed at the top of the calendar.
    currentYear: '',  // Current year displayed at the top of the calendar.

    focus: '',

    more: false,
    moreDate: null,
    moreEvents: [],

    staleEvent: false,
    refreshingEvents: false,

    mousingOverEvent: false, // Is the mouse currently over a calendar event?
    mousingOverMenu: false,  // Is the mouse currently over the on-hover event menu.
    eventMenuWidth: 350,

    selectedEventCopy: {}, // Saves a copy of the selected event on edit.
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
    /**
     * Metadata required by API calls.
     */
    apiCallMetadata () {
      return { operator_id: this.user.id, dlc: now(), }
    },
    editingSelectedEvent () {
      return this.dialog[DIALOGS.EVENT_GROUP].show
    },
    hasStaleEvents () {
      this.staleEvent
      return this.events.filter(event => event.stale).length > 0
    },
    selectedEventElementMenuPosition () {
      if (this.selectedElement) {
        const bounds = this.selectedElement.getBoundingClientRect()
        const screenWidth = this.$vuetify.breakpoint.width

        if (bounds.right + this.eventMenuWidth > screenWidth) {
          return { x: bounds.left - this.eventMenuWidth, y: bounds.top }
        }

        return { x: bounds.right , y: bounds.top } 
      }

      return { x: 0, y: 0 }
    },
    tableHeight () {
      return this.$vuetify.breakpoint.height - 220
    }
  },
  methods: {
    ...mapActions({
      createData: 'calendarEvent/createData',
      deleteData: 'calendarEvent/deleteData',
      fetchList: 'calendarEvent/fetchList',
      updateData: 'calendarEvent/updateData',
      successMessage: 'message/successMessage',
      errorMessage: 'message/errorMessage',
    }),
    addEvent (payload) {
      const newEvent = CalendarEventFactory.makeEvent(payload, true)
      newEvent.index = this.events.length
      this.events.push(newEvent)
      this.closeEventMenu()
    },
    addEventGroup (eventGroup) {
      // Save current max index for the total event list and use that to set the index for each item in the new event group.
      let maxEventIndex = this.events.length

      let newEvents = CalendarEventFactory.makeEventsFromGroup(eventGroup)
      newEvents.forEach((event, index) => event.index = maxEventIndex + index)

      const updatedEvents = [...this.events, ...newEvents]
      this.events = updatedEvents
    },
    closeEventMenu () {
      if (!this.editingSelectedEvent) {
        this.selectedOpen = false
        this.selectedElement = null
      }
    },
    deleteEvent (payload) {
      const eventIndex = payload.index
      const eventId = payload.id

      const updatedEvent = CalendarEventFactory.makeEvent(payload, this.events[eventIndex].created)
      updatedEvent.index = eventIndex
      updatedEvent.id = eventId
      updatedEvent.delete = true
      updatedEvent.stale = true
      updatedEvent.color = 'grey darken-2'
      updatedEvent.dark = true

      this.events[eventIndex] = updatedEvent
      this.forceCalendarUpdate()
      this.forceStaleEventUpdate()

      this.closeEventMenu()
    },
    deserialize (events) {
      let convertedEvents = []
      events.forEach((event, index) => {
        convertedEvents.push({
          id: event.id,
          index,
          color: event.color,
          dark: event.dark === 'Y',
          description: event.description,
          eventGroupType: event.event_group_type,
          eventType: event.event_type,
          name: event.name,
          start: event.date,
        })
      })

      return convertedEvents
    },
    /**
     * Close and reset a dialog window.
     * @param {string} type Dialog window type.
     */
    dialogClose (type) {
      this.dialog[type] = { show: false, payload: [], }
    },
    forceCalendarUpdate () {
      this.calendarInstance += 1
    },
    forceStaleEventUpdate () {
      this.staleEvent = !this.staleEvent
    },
    getEvents ({ start, end}) {
      this.currentYear = start.year
      this.currentMonth = monthNumberToName(start.month)
    },
    handleMoreEvents (event) {
      this.moreDate = event.date
      this.moreEvents = this.events.filter(event => event.start === this.moreDate)
      this.more = true
    },
    handleMouseLeaveEvent (index) {
      setTimeout(() => {
        if (this.selectedEvent.index === index && !this.mousingOverEvent && !this.mousingOverMenu) {
          this.closeEventMenu()
        }
      }, 150)
    },
    initTemplateConstants () {
      this.PRIMARY = COLOR.PRIMARY
      this.SECONDARY = COLOR.SECONDARY

      this.BACK_ICON = ICONS.BACK
      this.CALENDAR_ICON = ICONS.CALENDAR
      this.CLOSE_ICON = ICONS.CLOSE
      this.DELETE_ICON = ICONS.DELETE
      this.EDIT_ICON = ICONS.EDIT
      this.FORWARD_ICON = ICONS.FORWARD
      this.SAVE_ICON = ICONS.SAVE
      this.STALE_EVENT_ICON = ICONS.STALE_EVENT

      this.DEFAULT_TEXT = TEXT_COLOR.DEFAULT

      this.EVENT = DIALOGS.EVENT
      this.EVENT_GROUP = DIALOGS.EVENT_GROUP
    },
    /**
     * Opens the dialog for adding a single event and configures it.
     */
    openEventDialog ({ nativeEvent, event } = {}) {
      const newEvent = !nativeEvent && !event

      this.dialog[DIALOGS.EVENT].payload = []
      this.dialog[DIALOGS.EVENT].show = true
      this.dialog[DIALOGS.EVENT].config = {
        isEditing: !newEvent,
        selectedEvent: {}
      }

      if (event) {
        this.selectedEvent = event
        this.dialog[DIALOGS.EVENT].config.selectedEvent = {
          start: event.start,
          color: {
            color: event.color,
            dark: event.dark,
          },
          description: event.description,
          name: event.name,
          eventType: event.eventType,
          eventGroupType: event.eventGroupType,
          index: event.index,
          id: event.id,
        }
      }

      if (nativeEvent) {
        nativeEvent.stopPropagation()
      }
    },
    openEventDialogFromList(event) {
      this.openEventDialog({ nativeEvent: null, event })
      this.more = false
    },
    /**
     * Opens the dialog for adding multiple events and configures it.
     */
    openEventGroupDialog () {
      this.dialog[DIALOGS.EVENT_GROUP].payload = []
      this.dialog[DIALOGS.EVENT_GROUP].show = true
    },
    async refreshEvents () {
      this.refreshingEvents = true
      await this.fetchList()
        .then(events => {
          this.events = this.deserialize(events)
        })
        .catch(error => {
          this.errorMessage(`Failed to refresh calendar: ${error}`)
        })
        .finally(() => {
          this.refreshingEvents = false
        })
    },
    async saveEvents () {
      let autoRefresh = true
      let hasStaleEvents = false
      let payload = {}

      let crudPromises = []

      this.events
        .filter(event => event.stale)
        .forEach(async (event, index) => {
          hasStaleEvents = true

          payload = {
            color: event.color,
            dark: event.dark ? 'Y' : 'N',
            date: event.start,
            event_group_type: event.eventGroupType,
            event_type: event.eventType,
            name: event.name,
            description: event.description,
            ...this.apiCallMetadata
          }

          if(event.delete) {
            if (event.created) {
              return
            }
            crudPromises.push(this.deleteData(event.id))
          }
          else if (event.created) {
            crudPromises.push(this.createData(payload))
          }
          else {
            payload.id = event.id
            crudPromises.push(this.updateData(payload))
          }
        })

      try {
        await Promise.all(crudPromises)

        if (crudPromises.length && autoRefresh) {
          await this.refreshEvents()
          this.successMessage(`Calendar successfully updated.`)
        }
      }
      catch (error) {
        this.errorMessage(`Failed to update calendar: ${error}`)
      }
    },
    /**
     * Display the details of a calendar in a menu next to the calendar event's element.
     */
    showEvent (nativeEvent, event) {
      this.selectedEvent = event
      this.selectedElement = nativeEvent.target
      this.selectedOpen = true
  
      nativeEvent.stopPropagation()
    },
    updateEvent (payload) {
      const eventIndex = this.selectedEvent.index
      const eventId = this.selectedEvent.id

      const updatedEvent = CalendarEventFactory.makeEvent(payload, this.events[eventIndex].created)
      updatedEvent.index = eventIndex
      updatedEvent.id = eventId

      this.events[eventIndex] = updatedEvent
      this.forceCalendarUpdate()
      this.forceStaleEventUpdate()

      this.closeEventMenu()
    },
  },
  mounted () {
    this.$refs.calendar.checkChange()
    this.refreshEvents()
  },
  created () {
    this.initTemplateConstants()
  }
}
</script>

<style scoped>
.v-default-cursor {
  cursor: default !important;
}

.v-calendar >>> .v-event {
  width: 100% !important;
}
</style>