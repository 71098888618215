import { EVENT_TITLE, EVENT_GROUPS, EVENT_GROUP_COLOR, EVENT_GROUP_COLOR_MODIFIER, EVENT_GROUP_DARK, } from '../constants'
import { addDays, today } from '../services/utility.service'
import CalendarEvent from './dto/CalendarEvent'

/**
 * Factory for creating CalendarEvents.
 */
class CalendarEventFactory {

  constructor () {}

  makeEvent (payload, created = false) {
    let event = new CalendarEvent(payload.color, payload.description, payload.name, payload.start, payload.eventType, payload.eventGroupType)
    if (created) {
      event.created = created
    }
    event.stale = true

    return event
  }

  makeEventsFromGroup (eventGroup) {
    let totalDayOffset = 0

    let events = eventGroup.typeList.map((eventType, i) => {
      totalDayOffset += eventGroup.dayOffsetList[i]
      const eventDate = addDays(eventGroup.start, totalDayOffset)

      const payload = {
        eventGroupType: eventGroup.eventGroupType,
        eventType,
        name: `${eventGroup.titlePrefix}${eventGroup.titleList[i]}`,
        color: this.getEventColor(eventGroup.eventGroupType, eventType),
        description: eventGroup.descriptionTemplate,
        start: eventDate,
        
      }

      return this.makeEvent(payload, true)
    })

    return events
  }

  getEventColor (eventGroupType, eventType) {
    return {
      color: `${EVENT_GROUP_COLOR[eventGroupType]} ${EVENT_GROUP_COLOR_MODIFIER[eventType]}`,
      dark: EVENT_GROUP_DARK[eventType],
    }
  }

  getDefaultMagazineEventGroups () {
    return {
      start: today(),
      dayOffsetList: [0, 10, 21,],
      typeList: [
        EVENT_TITLE.COUNTS_TO_TRANSCON,
        EVENT_TITLE.DATA_FILE_TO_TRANSCON,
        EVENT_TITLE.MAILING_BEGINS,
      ],
      titleList: [
        EVENT_TITLE.COUNTS_TO_TRANSCON,
        EVENT_TITLE.DATA_FILE_TO_TRANSCON,
        EVENT_TITLE.MAILING_BEGINS,
      ],
      titlePrefix: 'MG2201',
      descriptionTemplate: 'Issue Apr-May. Includes Extension, Reconnect KMP and Rahki\'s Bold Deals.',
      eventGroupType: EVENT_GROUPS.MAGAZINES,
    }
  }

  getDefaultMaturityLetterEventGroups () {
    return {
      start: today(),
      dayOffsetList: [0, 4, 14, 1, 2,],
      typeList: [
        EVENT_TITLE.DATA_SWEEP,
        EVENT_TITLE.EMAIL_NOTIFICATION,
        EVENT_TITLE.RESPONSE_DATE,
        EVENT_TITLE.FILE_TO_PRINTER,
        EVENT_TITLE.MAIL_DATE,
      ],
      titleList: [
        EVENT_TITLE.DATA_SWEEP,
        EVENT_TITLE.EMAIL_NOTIFICATION,
        EVENT_TITLE.RESPONSE_DATE,
        EVENT_TITLE.FILE_TO_PRINTER,
        EVENT_TITLE.MAIL_DATE,
      ],
      titlePrefix: 'ML2201',
      descriptionTemplate: 'Maturity Dates: Aug 22 (7 months), May 22 (4 months)',
      eventGroupType: EVENT_GROUPS.MATURITY_LETTERS,
    }
  }

  getDefaultSeasonalEMessageEventGroups () {
    return {
      start: today(),
      dayOffsetList: [0, 7,],
      typeList: [
        EVENT_TITLE.DATA_FILE_DUE,
        EVENT_TITLE.DEPLOYMENT,
      ],
      titleList: [
        EVENT_TITLE.DATA_FILE_DUE,
        EVENT_TITLE.DEPLOYMENT,
      ],
      titlePrefix: 'EML2201',
      descriptionTemplate: 'Theme: Earth Day',
      eventGroupType: EVENT_GROUPS.SEASONAL_E_MESSAGE,
    }
  }

  getDefaultThankYouCardsEventGroups () {
    return {
      start: today(),
      dayOffsetList: [0, 2, 5, 1, 2,],
      typeList: [
        EVENT_TITLE.DATA_SWEEP,
        EVENT_TITLE.EMAIL_NOTIFICATION,
        EVENT_TITLE.RESPONSE_DATE,
        EVENT_TITLE.FILE_TO_PRINTER,
        EVENT_TITLE.MAIL_DATE,
      ],
      titleList: [
        EVENT_TITLE.DATA_SWEEP,
        EVENT_TITLE.EMAIL_NOTIFICATION,
        EVENT_TITLE.RESPONSE_DATE,
        EVENT_TITLE.FILE_TO_PRINTER,
        EVENT_TITLE.MAIL_DATE,
      ],
      titlePrefix: 'TYC2201',
      descriptionTemplate: '(MG2201) Paid Dates: Jan 3 - 14',
      eventGroupType: EVENT_GROUPS.THANK_YOU_CARDS,
    }
  }

}

const factory = new CalendarEventFactory()
export default factory